@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
#top .c-slider__catchcopy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); }

.u-taL {
  text-align: left !important; }

.u-taC {
  text-align: center !important; }
  @media screen and (max-width: 568px) {
    .u-taC--xs-taL {
      text-align: left !important; } }
  @media screen and (max-width: 768px) {
    .u-taC--sm-taL {
      text-align: left !important; } }
  @media screen and (max-width: 1024px) {
    .u-taC--md-taL {
      text-align: left !important; } }
  @media screen and (max-width: 1280px) {
    .u-taC--lg-taL {
      text-align: left !important; } }

.u-taR {
  text-align: right !important; }

.u-only-xs {
  display: none !important; }
  @media screen and (max-width: 568px) {
    .u-only-xs {
      display: block !important; } }

.u-only-sm {
  display: none !important; }
  @media screen and (max-width: 768px) {
    .u-only-sm {
      display: block !important; } }
  @media screen and (max-width: 568px) {
    .u-only-sm {
      display: none !important; } }

.u-only-md {
  display: none !important; }
  @media screen and (max-width: 1024px) {
    .u-only-md {
      display: block !important; } }
  @media screen and (max-width: 768px) {
    .u-only-md {
      display: none !important; } }

@media screen and (max-width: 1024px) {
  .u-only-lg {
    display: none !important; } }

.u-only-md {
  display: none !important; }
  @media screen and (max-width: 1024px) {
    .u-only-md {
      display: block !important; } }
  @media screen and (max-width: 768px) {
    .u-only-md {
      display: none !important; } }

.u-under-md {
  display: none !important; }
  @media screen and (max-width: 1024px) {
    .u-under-md {
      display: block !important; } }

.u-under-sm {
  display: none !important; }
  @media screen and (max-width: 768px) {
    .u-under-sm {
      display: block !important; } }

.u-not-under-sm {
  display: block !important; }
  @media screen and (max-width: 768px) {
    .u-not-under-sm {
      display: none !important; } }

.u-overflow-hidden {
  overflow: hidden !important; }

@keyframes fadeInAnime {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

#top .c-slider {
  padding: 0;
  line-height: 0; }
  #top .c-slider li {
    width: 100%;
    height: 720px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; }
    @media screen and (max-width: 568px) {
      #top .c-slider li {
        height: 500px; } }
  #top .c-slider__catchcopy {
    font-size: 48px;
    line-height: 1.5;
    text-align: center;
    margin: 0;
    width: 70%; }
    @media screen and (max-width: 568px) {
      #top .c-slider__catchcopy {
        width: 85%;
        font-size: 30px; } }
  #top .c-slider .slick-prev, #top .c-slider .slick-next {
    z-index: 1; }
  #top .c-slider .slick-prev {
    left: 25px; }
  #top .c-slider .slick-next {
    right: 25px; }

#top .p-mv {
  position: relative;
  width: 100%;
  height: 100vh; }
  @media screen and (max-width: 768px) {
    #top .p-mv {
      height: 600px; } }
  @media screen and (max-width: 568px) {
    #top .p-mv {
      height: 375px; } }
  #top .p-mv__movie {
    object-fit: cover;
    font-family: "object-fit: cover; object-position: 50% 55%;";
    object-position: 50% 55%;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation-name: fadeInAnime;
    animation-fill-mode: forwards;
    animation-duration: 4s;
    animation-iteration-count: 1;
    animation-timing-function: ease;
    animation-delay: 0.5s;
    animation-direction: normal; }
  #top .p-mv__logo {
    position: absolute;
    left: 85px;
    top: 135px;
    max-width: 334px;
    width: 100%; }
    @media screen and (max-width: 768px) {
      #top .p-mv__logo {
        max-width: 200px;
        top: 120px;
        left: 20px; } }
    @media screen and (max-width: 568px) {
      #top .p-mv__logo {
        max-width: 115px;
        top: 90px; } }
    #top .p-mv__logo > img {
      width: 100%; }

@media screen and (max-width: 834px) {
  #top #wrapper {
    padding-top: 40px; } }

#top .p-intro {
  padding-top: 0;
  padding-bottom: 0; }
  #top .p-intro__textArea {
    text-align: center; }
    @media screen and (max-width: 834px) {
      #top .p-intro__textArea {
        text-align-last: left; } }
  #top .p-intro__logo {
    max-width: 316px;
    margin: 0 auto; }
    @media screen and (max-width: 1024px) {
      #top .p-intro__logo {
        max-width: 270px; } }
    @media screen and (max-width: 834px) {
      #top .p-intro__logo {
        margin: 0; } }
    @media screen and (max-width: 768px) {
      #top .p-intro__logo {
        max-width: 256px; } }
    @media screen and (max-width: 568px) {
      #top .p-intro__logo {
        max-width: 150px; } }
  #top .p-intro__inner {
    position: relative;
    padding-top: 112px;
    padding-bottom: 260px; }
    @media screen and (max-width: 1280px) {
      #top .p-intro__inner {
        padding-bottom: 150px; } }
    @media screen and (max-width: 834px) {
      #top .p-intro__inner {
        padding-bottom: 130px;
        padding-top: 230px; } }
  #top .p-intro__imageArea {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media screen and (max-width: 1336px) {
      #top .p-intro__imageArea {
        padding: 0 30px; } }
    @media screen and (max-width: 834px) {
      #top .p-intro__imageArea {
        justify-content: end;
        align-items: start;
        padding-right: 0; } }
  #top .p-intro__image {
    max-width: 251px;
    width: 100%; }
    @media screen and (max-width: 1336px) {
      #top .p-intro__image {
        max-width: 230px; } }
    @media screen and (max-width: 1024px) {
      #top .p-intro__image {
        max-width: 160px; } }
    @media screen and (max-width: 834px) {
      #top .p-intro__image--left {
        margin-right: 10px;
        max-width: 130px; } }
    @media screen and (max-width: 568px) {
      #top .p-intro__image--left {
        max-width: 80px; } }
    @media screen and (max-width: 834px) {
      #top .p-intro__image--right {
        margin-top: 60px; } }
    @media screen and (max-width: 568px) {
      #top .p-intro__image--right {
        max-width: 100px; } }

#top .p-about {
  padding-top: 40px; }
  #top .p-about__inner {
    padding-top: 190px; }
    @media screen and (max-width: 1200px) {
      #top .p-about__inner {
        padding-top: 110px; } }
    @media screen and (max-width: 768px) {
      #top .p-about__inner {
        padding-bottom: 70px; } }
  #top .p-about__container {
    padding-bottom: 0; }
  #top .p-about__introArea {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px; }
    @media screen and (max-width: 768px) {
      #top .p-about__introArea {
        flex-direction: column-reverse; } }
    #top .p-about__introArea-text {
      max-width: 500px;
      margin-top: 100px; }
      @media screen and (max-width: 1367px) {
        #top .p-about__introArea-text {
          max-width: 415px;
          margin-right: 50px; } }
      @media screen and (max-width: 1024px) {
        #top .p-about__introArea-text {
          margin-right: 30px; } }
      @media screen and (max-width: 768px) {
        #top .p-about__introArea-text {
          margin-top: 40px; } }
  #top .p-about__title {
    max-width: 400px;
    width: 100%; }
    #top .p-about__title > img {
      width: 100%; }
    @media screen and (max-width: 768px) {
      #top .p-about__title {
        max-width: 300px; } }
    @media screen and (max-width: 568px) {
      #top .p-about__title {
        max-width: 230px;
        margin-bottom: 10px; } }
  #top .p-about__mainImage {
    position: relative;
    max-width: 768px;
    width: 100%;
    margin-top: -240px;
    margin-right: -115px; }
    @media screen and (max-width: 1367px) {
      #top .p-about__mainImage {
        margin-right: -65px; } }
    @media screen and (max-width: 1200px) {
      #top .p-about__mainImage {
        margin-top: -140px; } }
    @media screen and (max-width: 768px) {
      #top .p-about__mainImage {
        margin-right: 0;
        max-width: 400px;
        margin-left: auto;
        right: -40px; } }
    @media screen and (max-width: 568px) {
      #top .p-about__mainImage {
        max-width: 270px;
        right: -30px; } }
  #top .p-about__conceptGrid {
    display: flex;
    justify-content: space-between; }
    @media screen and (max-width: 1200px) {
      #top .p-about__conceptGrid {
        align-items: end; } }
    @media screen and (max-width: 768px) {
      #top .p-about__conceptGrid {
        flex-direction: column;
        align-items: start; } }
  #top .p-about__conceptArea {
    padding-right: 80px;
    padding-bottom: 107px;
    padding-top: 40px;
    margin-left: 50px; }
    @media screen and (max-width: 1024px) {
      #top .p-about__conceptArea {
        margin-left: 30px; } }
    @media screen and (max-width: 768px) {
      #top .p-about__conceptArea {
        margin-left: auto;
        padding-right: 0;
        padding-bottom: 60px; } }
    #top .p-about__conceptArea > p {
      max-width: 520px;
      margin-left: auto;
      padding-bottom: 50px; }
      @media screen and (max-width: 768px) {
        #top .p-about__conceptArea > p {
          padding-bottom: 0; } }
    #top .p-about__conceptArea-btn {
      text-align: right; }
    #top .p-about__conceptArea-image {
      position: relative;
      z-index: 2; }
  #top .p-about__conceptImage {
    max-width: 413px; }
    @media screen and (max-width: 1200px) {
      #top .p-about__conceptImage {
        max-width: 300px; } }
    @media screen and (max-width: 768px) {
      #top .p-about__conceptImage {
        max-width: 300px; } }
    @media screen and (max-width: 568px) {
      #top .p-about__conceptImage {
        max-width: 200px; } }
    #top .p-about__conceptImage-bubble {
      width: 150%;
      position: absolute;
      left: -33%;
      top: -33%; }
  #top .p-about__bottomImage {
    max-width: calc(1306px + (100% - 1306px)/2);
    position: relative;
    top: -50px; }
    @media screen and (max-width: 1336px) {
      #top .p-about__bottomImage {
        max-width: calc(100% - 30px); } }
    @media screen and (max-width: 768px) {
      #top .p-about__bottomImage {
        max-width: calc(100% - 20px);
        top: 0; } }
    @media screen and (max-width: 568px) {
      #top .p-about__bottomImage {
        max-width: calc(100% - 30px/2); } }

#top .p-news__bg {
  padding-bottom: 100px; }
  @media screen and (max-width: 768px) {
    #top .p-news__bg {
      padding-bottom: 40px; } }

@media screen and (max-width: 568px) {
  #top .p-news__inner {
    margin: 0 35px; } }

#top .p-news__btnArea {
  text-align: right;
  margin-top: 40px; }

#top .p-customer__titleArea {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  @media screen and (max-width: 768px) {
    #top .p-customer__titleArea {
      flex-direction: column;
      align-items: start; } }
  @media screen and (max-width: 768px) {
    #top .p-customer__titleAreaWrapper {
      padding-bottom: 30px; } }

#top .p-customer__titleImage {
  margin-right: -70px;
  max-width: 560px;
  width: 100%; }
  @media screen and (max-width: 1200px) {
    #top .p-customer__titleImage {
      margin-right: -30px;
      max-width: 500px; } }
  @media screen and (max-width: 1024px) {
    #top .p-customer__titleImage {
      max-width: 400px; } }
  @media screen and (max-width: 768px) {
    #top .p-customer__titleImage {
      margin-right: -20px;
      margin-left: auto; } }
  @media screen and (max-width: 568px) {
    #top .p-customer__titleImage {
      max-width: 215px;
      margin-right: -15px; } }

#top .p-customer__btnArea {
  text-align: right;
  margin-right: -20px; }
  @media screen and (max-width: 568px) {
    #top .p-customer__btnArea {
      margin-right: 0; } }

#top .p-customer__bgContainer {
  position: relative;
  padding: 85px 0 45px; }
  @media screen and (max-width: 768px) {
    #top .p-customer__bgContainer {
      padding-top: 30px; } }
  #top .p-customer__bgContainer::after {
    content: '';
    height: 100%;
    width: calc(100% - 90px);
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, #F0F5F7, rgba(240, 245, 247, 0));
    z-index: -1; }
    @media screen and (max-width: 768px) {
      #top .p-customer__bgContainer::after {
        width: calc(100% - 25px); } }

#top .p-customer__Grid {
  display: flex;
  justify-content: space-between; }

#top .p-customer__textArea {
  margin-right: 30px; }
  @media screen and (max-width: 768px) {
    #top .p-customer__textArea {
      margin-right: 0; } }

@media screen and (max-width: 768px) {
  #top .p-customer__catch {
    font-size: 25px; } }

@media screen and (max-width: 568px) {
  #top .p-customer__catch {
    font-size: 18px; } }

#top .p-customer__bubbleImage {
  margin-right: 80px; }
  @media screen and (max-width: 768px) {
    #top .p-customer__bubbleImage {
      position: absolute;
      right: 15px;
      top: 15px;
      max-width: 200px;
      width: 100%;
      margin-right: 0; } }
  @media screen and (max-width: 568px) {
    #top .p-customer__bubbleImage {
      max-width: 115px; } }

#top .p-salonProductLink {
  display: flex; }
  #top .p-salonProductLink__title {
    color: #222222;
    margin-bottom: 0; }
    @media screen and (max-width: 568px) {
      #top .p-salonProductLink__title {
        font-size: 18px; } }
  #top .p-salonProductLink__infoArea {
    padding: 30px 0 0 40px;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    justify-content: space-between; }
    @media screen and (max-width: 768px) {
      #top .p-salonProductLink__infoArea {
        padding: 15px 0 0 15px; } }
  #top .p-salonProductLink__btnArea {
    text-align: right;
    margin-right: -20px; }
    @media screen and (max-width: 768px) {
      #top .p-salonProductLink__btnArea {
        margin-right: -15px; } }
    #top .p-salonProductLink__btnArea > .c-waveBtn {
      height: 100%; }
      @media screen and (max-width: 768px) {
        #top .p-salonProductLink__btnArea > .c-waveBtn {
          padding: 10px 35px 10px 23px; } }
  @media (any-hover: hover) {
    #top .p-salonProductLink:hover .c-waveBtn {
      color: #24671E !important; }
      #top .p-salonProductLink:hover .c-waveBtn::before {
        left: -25%;
        top: -60%;
        height: 560%; } }
  #top .p-salonProductLink__image {
    max-width: 232px; }
    @media screen and (max-width: 768px) {
      #top .p-salonProductLink__image {
        max-width: 150px; } }
    @media screen and (max-width: 568px) {
      #top .p-salonProductLink__image {
        max-width: 100px; } }
    #top .p-salonProductLink__image > img {
      object-fit: cover;
      font-family: "object-fit: cover; object-position: center;";
      object-position: center;
      width: 100%;
      height: 100%; }

#top .p-general__mainArea {
  display: flex;
  align-items: center;
  margin-bottom: 100px; }
  @media screen and (max-width: 568px) {
    #top .p-general__mainArea {
      flex-direction: column;
      margin-top: 30px; } }
  #top .p-general__mainArea-image {
    margin-right: 40px;
    width: 100%; }
    @media screen and (max-width: 1024px) {
      #top .p-general__mainArea-image {
        margin-right: 30px; } }
    @media screen and (max-width: 568px) {
      #top .p-general__mainArea-image {
        margin-right: 0; } }
  #top .p-general__mainArea-info {
    padding-top: 60px;
    max-width: 440px; }
    @media screen and (max-width: 1024px) {
      #top .p-general__mainArea-info {
        width: 80%; } }
    @media screen and (max-width: 768px) {
      #top .p-general__mainArea-info {
        padding-top: 30px; } }
    @media screen and (max-width: 568px) {
      #top .p-general__mainArea-info {
        width: 100%;
        max-width: 100%; } }

#top .p-general__product {
  padding: 100px 60px 80px;
  background-position: bottom;
  background-image: url("/inc/image/top/img_generalProduct_bg.png");
  background-position: center;
  background-repeat: no-repeat; }
  @media screen and (max-width: 768px) {
    #top .p-general__product {
      padding: 50px 30px 40px; } }
  @media screen and (max-width: 568px) {
    #top .p-general__product {
      padding: 30px 20px 20px;
      background-image: none; } }

@media screen and (max-width: 568px) {
  #top .p-general__bgArea {
    padding-bottom: 50px; } }

#top .p-general__productWrapper {
  max-width: 930px;
  margin: 0 auto;
  margin-bottom: 50px; }
  @media screen and (max-width: 568px) {
    #top .p-general__productWrapper {
      margin-bottom: 10px; } }

#top .p-general__productList {
  display: flex;
  justify-content: space-between;
  margin: 0 -20px; }
  @media screen and (max-width: 768px) {
    #top .p-general__productList {
      margin: 0 -10px; } }
  @media screen and (max-width: 568px) {
    #top .p-general__productList {
      flex-direction: column; } }
  #top .p-general__productList-item {
    padding: 0 20px; }
    @media screen and (max-width: 768px) {
      #top .p-general__productList-item {
        padding: 0 10px; } }
    @media screen and (max-width: 568px) {
      #top .p-general__productList-item {
        margin-bottom: 20px; } }
    #top .p-general__productList-item:last-child {
      margin-bottom: 0; }

#top .p-general__btnArea {
  text-align: right; }

#top .p-topCvContents {
  max-width: 1366px;
  margin: 0 auto;
  position: relative; }
  #top .p-topCvContents::after {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 0; }
    @media screen and (max-width: 568px) {
      #top .p-topCvContents::after {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
        height: 10%; } }
  #top .p-topCvContents__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    #top .p-topCvContents__bg img {
      object-fit: cover;
      object-position: center;
      font-family: "object-fit: cover; object-position: center;";
      width: 100%;
      height: 100%; }
    @media screen and (max-width: 568px) {
      #top .p-topCvContents__bg {
        position: static;
        height: 430px; } }
  #top .p-topCvContents__wrapper {
    background-image: url("/inc/image/top/top_cvBg-under.jpg");
    background-size: cover; }
    @media screen and (max-width: 568px) {
      #top .p-topCvContents__wrapper {
        background-color: #222222;
        background-image: none; } }
  @media screen and (max-width: 568px) {
    #top .p-topCvContents__title {
      font-size: 22px; } }
  #top .p-topCvContents__text {
    font-weight: 500; }
    @media screen and (max-width: 568px) {
      #top .p-topCvContents__text {
        font-size: 14px; } }
  #top .p-topCvContents__inner {
    max-width: 1170px;
    margin: 0 auto;
    padding: 60px 0; }
    @media screen and (max-width: 1200px) {
      #top .p-topCvContents__inner {
        padding: 60px 30px; } }
    @media screen and (max-width: 768px) {
      #top .p-topCvContents__inner {
        padding: 60px 20px; } }
    @media screen and (max-width: 568px) {
      #top .p-topCvContents__inner {
        padding: 60px 15px; } }
  #top .p-topCvContents__mainArea {
    position: relative;
    z-index: 1;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 480px; }
    @media screen and (max-width: 568px) {
      #top .p-topCvContents__mainArea {
        margin-bottom: 0;
        padding: 0 20px; } }
  #top .p-topCvContents__logo {
    max-width: 296px;
    margin: 0 auto 35px; }
    @media screen and (max-width: 568px) {
      #top .p-topCvContents__logo--pc {
        display: none; } }
    #top .p-topCvContents__logo--sp {
      display: none; }
      @media screen and (max-width: 568px) {
        #top .p-topCvContents__logo--sp {
          display: block;
          position: absolute;
          left: 50%;
          top: 70px;
          transform: translateX(-50%);
          z-index: 3; } }
  #top .p-topCvContents__buttonArea {
    position: relative;
    z-index: 1;
    margin: 0 auto 30px; }
  #top .p-topCvContents__button {
    width: 100%;
    height: 100%;
    padding: 28px 45px 28px 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    color: #24671E; }
    @media screen and (max-width: 568px) {
      #top .p-topCvContents__button {
        padding: 28px 35px 28px 26px; } }
    #top .p-topCvContents__button:hover {
      color: #FFFFFF; }
  #top .p-topCvContents__subBottunArea {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    margin: 0 -10px; }
    @media screen and (max-width: 1024px) {
      #top .p-topCvContents__subBottunArea {
        flex-wrap: wrap;
        margin: -10px;
        justify-content: space-around; } }
  #top .p-topCvContents__subBottun {
    margin: 0 10px; }
    @media screen and (max-width: 1024px) {
      #top .p-topCvContents__subBottun {
        max-width: calc((100% - 10px*4)/2);
        margin: 10px; } }
    @media screen and (max-width: 568px) {
      #top .p-topCvContents__subBottun {
        max-width: 250px; } }
